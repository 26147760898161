//
//
// rikupuu.fi website
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// ALL RIGHTS RESERVED
//
//


$textcolor: #3b3b3b;

@use "bulma/sass/utilities"  with (
  $family-primary: '"Alegreya", serif',
  $background: #fff,
  $text: $textcolor,
  $link: $textcolor
);

@use "bulma/sass/base";
@use "bulma/sass/helpers/typography";
@use "bulma/sass/components/navbar";
@use "bulma/sass/components/modal";
@use "bulma/sass/elements/content";
@use "bulma/sass/layout/section";
@use "bulma/sass/layout/footer";
@use "bulma/sass/themes";
@use "bulma/sass/utilities/mixins";

@import './font-faces.scss';



//
// Site-wide styling
//
html,
body {
	height: 100%;
  font-size: 16px;
}


a,
a:link,
a:visited,
a:active {
    text-decoration: underline;
    transition: none !important;
}

a:hover {
    text-decoration: none;
    border-bottom: none;
    color: #b26925;
}


h1 {
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 2rem;
    word-break: break-word;
}

h2 {
    font-size: 1.7rem;
    line-height: 1.7rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

h3{
    font-size: 1.4rem;
    line-height: 1.4rem;
    font-weight: 600;
    margin-bottom: 1rem;
}


ul {
  list-style-type: disc;

  > li > ul { list-style-type: circle; }
}


.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


.modal {
  .modal-content {
    &.is-huge {
        width: 100%;
        margin: 0px 20px;
        max-height: calc(100vh - 40px);
    }
    @include mixins.tablet {
        &.is-huge {
            width: 100%;
            margin: 0px 20px;
            max-height: calc(100vh - 40px);
        }
    }

    &.is-image {
        text-align: center;

        &.is-huge {
            width:auto;
        }
  
        img {
            max-height: calc(100vh - 50px);
        }
    }

    .prev-img,
    .next-img {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 50px;
      color: #fff;
      display: flex;

      cursor: pointer;

      align-items: center;
      justify-content: center;

      &:hover {
        background-color: rgba(0, 0, 0, 0.3);

        .prev-button, .next-button { opacity: 1; }
  
      }

      .prev-button,
      .next-button {
        font-size: 5rem;
        text-shadow: 1px 1px 5px rgba(0, 0, 0, 1);
        opacity: .7;
      }
    }

    .prev-img {
      left: 0px;
    }

    .next-img {
      right: 0px;
    }
  }
}


nav.navbar {
    .navbar-brand {
      .navbar-item img {
        max-height: 6rem;
        border-radius: 20px;
      }
    }
  
    .navbar-menu {
      .navbar-start {
        .navbar-item {
          font-size: 2rem;
          font-weight: 600;
          padding: 0.5rem 1rem;
  
          &:hover {
            background-color: transparent;
            color: $textcolor;
            text-decoration: underline;
          }
        }
      }
  
      .navbar-end {
        .navbar-item .instagram-topnav {
          display: flex;
          justify-content: center;
        }

        .navbar-item .language-select {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          align-items: center;

          width: 80px;
  
          > .flag-icon { padding-top: 10px; }
  
          > .flag-text {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 0.875rem;
          }
        }

        .navbar-item img {
          max-height: 3rem;
        }

        .navbar-item:hover {
          color: #b26925;
        }
      }
  
      .navbar-item {
        text-transform: uppercase;
        text-decoration: none;
      }


    }
}


section.kauppa-sivu {
  .kauppa-grid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, 250px);

    margin-bottom: 2rem;

    .kauppa-item {
        display: flex;
        cursor: pointer;
        flex-direction: column;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-decoration: none;

        &:hover {
          color:#b26925;
          background-color: #fafafa;
        }
    }
  }

  @media screen and (max-width: 580px) {
    .kauppa-grid {
      grid-template-columns: repeat(auto-fill, 100%);
    }
  }
}


section.tuote-sivu {
  .tuote {
    display: flex;
    column-gap: 20px;

    .tuote-kuvat {
      .karuselli {
        height: 350px;
        width: 499px;
        display: flex;
        position: relative;
        align-items: center;
        background: #eee;
        overflow: hidden;
        margin-bottom: 5px;

        .karuselli-edellinen,
        .karuselli-seuraava {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 50px;
          color: #fff;
          display: flex;
    
          cursor: pointer;
    
          align-items: center;
          justify-content: center;
    
          &:hover {
            background-color: rgba(0, 0, 0, 0.3);
    
            .seuraava-nappi, .edellinen-nappi { opacity: 1; }
      
          }
    
          .edellinen-nappi,
          .seuraava-nappi {
            font-size: 4rem;
            text-shadow: 1px 1px 5px rgba(0, 0, 0, 1);
            opacity: .7;
          }
        }
    
        .karuselli-edellinen {
          left: 0px;
        }
    
        .karuselli-seuraava {
          right: 0px;
        }

        > .karuselli-kuva {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          justify-content: center;
          cursor: zoom-in;

          > img {
            max-height: 100%;
          }
        }
      }

      .karuselli-navigaatio {
        width: 499px;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        > .navigaatio-kuva {
          width: 121px;
          height: 80px;
          overflow: hidden;

          padding: 3px;

          cursor: pointer;
        }

        > .navigaatio-kuva.valittu {
          padding: 0;
          border: 3px solid black;
        }
      }
    }
  }


  .tuote-info {
    font-size: 1.125rem;
  }


  @media screen and (max-width: 550px) {
    .tuote {
      display: block;

      .tuote-kuvat {
        width: 100%;

        margin-bottom: 20px;
  
        > .karuselli {
          width: 100%;
        }

        > .karuselli-navigaatio {
          width: 100%;

          > .navigaatio-kuva {
            width: 32%;
          }
        }
      }
  
    }
  }

  @media screen and (max-width: 1023px) and (min-width: 551px) {
    .tuote {
      display: block;

      .tuote-kuvat {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-bottom: 20px;
  
        > .karuselli-navigaatio {
          width: 100%;
        }
      }
  
    }
  }
}


section.tyot-sivu {
  .tyokuvat {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

    .tyokuva {
      border: 1px solid #ccc;
      border-radius: 10px;
      padding: 10px;
      width: 400px;
      max-width: 400px;
      min-width: 400px;
      text-align: center;

      cursor: pointer;

      &:hover {
        color:#b26925;
        background-color: #fafafa;
      }
    }

    @media screen and (max-width: 450px) {
      .tyokuva {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
      }
    }
  }
}


section.tietoa-sivu {
  .rikukuva-container {
    float: right;
    margin: 0 0 20px 20px;

    .rikukuva {
      border: 1px solid #cecece;
      padding: 5px;
      border-radius: 10px;

      width: 300px;
    }
  }

  @include mixins.mobile {
    .rikukuva-container {
      float: none;
      text-align: center;
    }
  }

  .vanhatkuvat {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

    .vanhakuva {
      border: 1px solid #ccc;
      border-radius: 10px;
      padding: 10px;
      width: 200px;
      max-width: 200px;
      min-width: 200px;

      cursor: pointer;

      &:hover {
        color:#b26925;
        background-color: #fafafa;
      }
    }

    @media screen and (max-width: 450px) {
      .vanhakuva {
        width: 100%;
        max-width: 100%;
        min-width: 100%;

        > img { width: 100%; }
      }
    }
  }
}


@include mixins.mobile {
  .site-footer {
    flex-direction: column;
    align-items: center;
    row-gap: 30px;

    .footer-left {
      text-align: center;
    }

    .footer-right {
      justify-content: center;
    }
  }
}

.site-footer {
  display: flex;
  justify-content: space-between;

  .footer-left,
  .footer-center,
  .footer-right {
    flex-grow: 1;
  }

  .footer-center {
    text-align: center;
  }

  .footer-right {
    display: flex;
    justify-content: flex-end;

    .instagram-footer {
      display: block;
      width: 40px;
      padding: 5px 5px 2px 5px;

      line-height: 1rem;

      border-radius: 5px;

      cursor: pointer;

      &:hover {
        background-color: rgb(240, 242, 244);
      }
    }

    .language-select-footer {
      display: block;
      width: 70px;
      padding: 5px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;

      cursor: pointer;

      border-radius: 5px;

      &:hover {
        background-color: rgb(240, 242, 244);
      }

      .flag-text {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 0.778rem;
      }
    }
  }
}
