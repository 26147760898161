//
//
// rikupuu.fi website
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// ALL RIGHTS RESERVED
//
//


@font-face {
    font-display: swap;
    font-family: 'Alegreya';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/Alegreya-Regular.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Alegreya';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/Alegreya-Italic.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Alegreya';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/Alegreya-SemiBold.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Alegreya';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/Alegreya-Bold.ttf') format('truetype');
}
